var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-row",
    [
      _c(
        "b-col",
        { attrs: { cols: "12" } },
        [
          _c(
            "b-form",
            [
              !_vm.recuperarClave
                ? _c(
                    "b-form-group",
                    {
                      staticClass: "text-left",
                      attrs: { label: "Contraseña actual" }
                    },
                    [
                      _c("b-form-input", {
                        attrs: {
                          type: "password",
                          state: _vm.$v.formRC.actual.$dirty
                            ? !_vm.$v.formRC.actual.$error
                            : null
                        },
                        model: {
                          value: _vm.$v.formRC.actual.$model,
                          callback: function($$v) {
                            _vm.$set(_vm.$v.formRC.actual, "$model", $$v)
                          },
                          expression: "$v.formRC.actual.$model"
                        }
                      }),
                      _c("b-form-invalid-feedback", [
                        _vm._v(_vm._s(_vm.validarCampo(_vm.$v.formRC.actual)))
                      ])
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "b-form-group",
                {
                  staticClass: "text-left",
                  attrs: { label: "Nueva contraseña" }
                },
                [
                  _c("b-form-input", {
                    attrs: {
                      type: "password",
                      state: _vm.$v.formRC.nueva.$dirty
                        ? !(
                            _vm.$v.formRC.nueva.$error ||
                            _vm.progress < _vm.numPasosProgress
                          )
                        : null
                    },
                    model: {
                      value: _vm.$v.formRC.nueva.$model,
                      callback: function($$v) {
                        _vm.$set(_vm.$v.formRC.nueva, "$model", $$v)
                      },
                      expression: "$v.formRC.nueva.$model"
                    }
                  }),
                  _c("b-form-invalid-feedback", [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$v.formRC.nueva.$error
                            ? "Este campo es obligatorio"
                            : _vm.progressV
                        ) +
                        " "
                    )
                  ])
                ],
                1
              ),
              _c(
                "b-form-group",
                {
                  staticClass: "text-left",
                  attrs: { label: "Confirmar contraseña" }
                },
                [
                  _c("b-form-input", {
                    attrs: {
                      type: "password",
                      state: _vm.$v.formRC.confirmada.$dirty
                        ? !(
                            _vm.$v.formRC.nueva.$error ||
                            _vm.progress < _vm.numPasosProgress
                          )
                        : null
                    },
                    model: {
                      value: _vm.$v.formRC.confirmada.$model,
                      callback: function($$v) {
                        _vm.$set(_vm.$v.formRC.confirmada, "$model", $$v)
                      },
                      expression: "$v.formRC.confirmada.$model"
                    }
                  }),
                  _c("b-form-invalid-feedback", [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$v.formRC.confirmada.$error
                            ? "Este campo es obligatorio"
                            : _vm.progressV
                        ) +
                        " "
                    )
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-col",
        { staticClass: "py-2", attrs: { cols: "12" } },
        [
          _c(
            "b-progress",
            { staticClass: "w-100", attrs: { max: _vm.numPasosProgress } },
            [
              _c("b-progress-bar", {
                attrs: { variant: _vm.getVariant, value: _vm.validarClave }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-col",
        { staticClass: "pb-2 content-center", attrs: { cols: "12" } },
        [
          _c(
            "b-button",
            {
              staticClass: "mt-3 mb-2",
              attrs: {
                variant: "danger",
                disabled:
                  _vm.$v.formRC.$invalid || _vm.progress < _vm.numPasosProgress
              },
              on: { click: _vm.cambiarClave }
            },
            [
              _c("i", { staticClass: "las la-check-circle" }),
              _vm._v(" Cambiar ")
            ]
          )
        ],
        1
      ),
      _c("b-col", { attrs: { cols: "12" } }, [
        _c(
          "div",
          { class: "text-center " + (_vm.recuperarClave ? "fs-12" : "fs-16") },
          [
            _c("p", [
              _vm._v(
                "Ten en cuenta que tu contraseña debe cumplir con lo siguiente:"
              )
            ]),
            _c("p", { staticClass: "mb-0" }, [
              _c("i", {
                class:
                  "las " +
                  (_vm.feedBack.minMax
                    ? "la-check-circle success"
                    : "la-times-circle danger")
              }),
              _vm._v(
                " Debe tener mínimo " +
                  _vm._s(_vm.minLength) +
                  " " +
                  _vm._s(_vm.textoMinLength) +
                  " y máximo " +
                  _vm._s(_vm.maxLength) +
                  ". "
              )
            ]),
            _c("p", { staticClass: "mb-0" }, [
              _c("i", {
                class:
                  "las " +
                  (_vm.feedBack.alphanumeric
                    ? "la-check-circle success"
                    : "la-times-circle danger")
              }),
              _vm._v(" Debe ser alfanumérica. ")
            ]),
            _c("p", { staticClass: "mb-0" }, [
              _c("i", {
                class:
                  "las " +
                  (_vm.feedBack.minMinusculas
                    ? "la-check-circle success"
                    : "la-times-circle danger")
              }),
              _vm._v(
                " Debe tener mínimo " +
                  _vm._s(_vm.minimoLetrasMinusculas) +
                  " " +
                  _vm._s(_vm.textoMinimoLetrasMinusculas) +
                  " "
              )
            ]),
            _c("p", { staticClass: "mb-0" }, [
              _c("i", {
                class:
                  "las " +
                  (_vm.feedBack.minMayusculas
                    ? "la-check-circle success"
                    : "la-times-circle danger")
              }),
              _vm._v(
                " Debe tener mínimo " +
                  _vm._s(_vm.minimoLetrasMayusculas) +
                  " " +
                  _vm._s(_vm.textoMinimoLetrasMayusculas) +
                  " "
              )
            ]),
            _c("p", { staticClass: "mb-0" }, [
              _c("i", {
                class:
                  "las " +
                  (_vm.feedBack.maxSpecialCharacters
                    ? "la-check-circle success"
                    : "la-times-circle danger")
              }),
              _vm._v(
                " Debe tener mínimo " +
                  _vm._s(_vm.specialCharacters) +
                  " " +
                  _vm._s(_vm.textoSpecialCharacters) +
                  " "
              )
            ]),
            _c("p", { staticClass: "mb-0" }, [
              _c("i", {
                class:
                  "las " +
                  (_vm.feedBack.differentId
                    ? "la-check-circle success"
                    : "la-times-circle danger")
              }),
              _vm._v(" No puede ser igual a tu número de identificación. ")
            ]),
            _vm.isOnLogin
              ? _c("p", { staticClass: "mb-0" }, [
                  _c("i", {
                    class:
                      "las " +
                      (_vm.feedBack.differentLast
                        ? "la-check-circle success"
                        : "la-times-circle danger")
                  }),
                  _vm._v(" No puede ser igual que la contraseña anterior. ")
                ])
              : _vm._e(),
            _c("p", { staticClass: "mb-0" }, [
              _c("i", {
                class:
                  "las " +
                  (_vm.feedBack.passwordEqual
                    ? "la-check-circle success"
                    : "la-times-circle danger")
              }),
              _vm._v(" Deben coincidir los campos de la nueva contraseña. ")
            ])
          ]
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }